import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { RebateEntry, RebateFile, RebateForm, RebateLineItem } from "src/app/models/dashboard/rebates";
import { ProUser } from "src/app/models/pro-user";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";
import { Table } from "primeng/table";
import { Message } from "primeng/api";

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
	selector: "app-rebate-claim-form",
	templateUrl: "./rebate-claim-form.component.html",
	styleUrls: ["./rebate-claim-form.component.scss"],
})
export class RebateClaimFormComponent implements OnInit {
	@Input() rebate: RebateEntry;

	@Output() onSubmit: EventEmitter<RebateEntry> = new EventEmitter();

	public step: number = 1;

	public rebateForm: UntypedFormGroup;
	private user: ProUser;
	public item: RebateLineItem[] = [];
	public RequiresAdditionalUpload: Boolean = false;
	public programDirections: RebateLineItem[] = [];
  public columns: any[] = [];
  public countPrograms: number;
  public view: string = "Brand";
  public errorMessage: Message[] = [];
  public excelData: any[];


  constructor(private dataService: DataService, private fb: UntypedFormBuilder, private store: Store<IAppState>, private datePipe: DatePipe) { }

	ngOnInit(): void {
		this.store.pipe(select(selectUser)).subscribe((u) => (this.user = u));

		if (!this.rebate.rebateForm) {
			this.rebate.rebateForm = new RebateForm();
    }

		this.columns = [
			{ header: "Rebate Types", field: "rebateType" },
      { header: "Pro Code", field: "proCode" },
      { header: "Cat Number", field: "catalogNumber" },
      { header: "Brand", field: "brand" },
			{ header: "Model", field: "modelName" },
			{ header: "Reimbursement", field: "reimbursement" },
			{ header: "Quantity", field: "quantity" },
    ];


    if (!this.rebate.rebateLineItems)
    {
      this.dataService.getRebateProgramProducts(this.rebate.rebateProgram.vendorId, this.rebate.rebateProgram.startDate, this.rebate.rebateProgram.endDate, this.rebate.rebateProgram.view).subscribe((r: any) => {

       //console.log(this.rebate.rebateProgram.view)
        this.view = this.rebate.rebateProgram.view;
				this.rebate.rebateLineItems = r.map((i) => new RebateLineItem(i));

        this.item = r.map((i) => new RebateLineItem(i));
			});
		}

		this.rebateForm = this.fb.group({
			fname: [this.rebate.rebateForm.fname || this.user.name.split(" ")[0], Validators.required],
			lname: [this.rebate.rebateForm.lname || this.user.name.split(" ")[1], Validators.required],
			email: [this.rebate.rebateForm.email || this.user.email, Validators.required],
			phone: [this.rebate.rebateForm.phone, Validators.required],
			memo: [this.rebate.rebateForm.memo, [Validators.required, Validators.minLength(5)]],
		});
	}


	validateRebate(item: RebateLineItem) {
		this.dataService.validateRebate(item.modelID, item.quantity).subscribe((resp) => {
			item.available = Math.max(0, resp.available);
			item.status = resp.status;

			//item.available = Math.max(0, resp.available);
			//item.status = resp.status;
		});
	}

	get total() {
		this.rebate.rebateLineItems = this.item;
		return this.rebate.rebateLineItems.filter((i) => i.quantity > 0).reduce((a, b) => a + b.quantity * b.reimbursement, 0);
	}
	back() {
		if (this.step > 1) {
			this.step--;
		}
	}
	next() {
		if (this.step < 3) {
			//Just standard upload, no secondary required.
			if (this.step == 2 && !this.rebate.rebateFiles?.length && !this.RequiresAdditionalUpload) {
				this.store.dispatch(new ErrorMessageSet("Please attach at least one supporting document for POS"));
				return;
			}
			//Just both upload,  secondary required.
			if (this.step == 2 && !this.rebate.rebateFiles?.length && !this.rebate.rebateFilesSecondary?.length && this.RequiresAdditionalUpload) {
				this.store.dispatch(new ErrorMessageSet("Please attach at least one supporting document for POS AND additional proof of performance, such as a screenshot of a social media post "));
				return;
			}
			//only secondary missing.
			if (this.step == 2 && this.rebate.rebateFiles?.length && !this.rebate.rebateFilesSecondary?.length && this.RequiresAdditionalUpload) {
				this.store.dispatch(new ErrorMessageSet("Please attach additional proof of performance, such as a screenshot of a social media post "));
				return;
			}
			//only Primary missing.
			if (this.step == 2 && this.rebate.rebateFilesSecondary?.length && !this.rebate.rebateFiles?.length && this.RequiresAdditionalUpload) {
				this.store.dispatch(new ErrorMessageSet("Please attach at least one supporting document for POS"));
				return;
			}

			//Need to audit type chosen
			if (this.step == 1) {
				const selectedItems = this.item.filter((i) => i.quantity > 0);

				if (selectedItems.length == 0) {
					this.store.dispatch(new ErrorMessageSet("At minimum, you must select one model for this program."));
					return;
				}

        selectedItems.forEach((x: any) =>
        {
					const index = this.programDirections.filter((item) => item.rebateTypeID === x.rebateTypeID);
          if (index.length > 0)
          {
          }
          else
          {
						if (x.rebateDirections != null) this.programDirections.push(x);
						if (x.rebateTypeID === 6) this.RequiresAdditionalUpload = true;
					}
        });

        this.countPrograms = this.programDirections.length;

			}
			this.step++;
		}
	}

  //this.columns
  //modelID	modelNumber	modelName

  
  exportExcel() {
    const columnsToExport = ['rebateType', 'proCode', 'catalogNumber', 'brand', 'modelName', 'reimbursement', 'quantity'];

    const exportData = this.item.map((row) => {
      return columnsToExport.reduce((obj, key) => {
        obj[key] = row[key];
        return obj;
      }, {});
    });

    import('xlsx').then((xlsx) => {
      const headers = [["Rebate Types", "Pro Code", "Cat Number", "Brand", "Model", "Reimbursement", "Quantity"]];
      const worksheet = xlsx.utils.json_to_sheet(exportData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx',type: 'array'});
      this.saveAsExcelFile(excelBuffer, 'View_' + this.view + '_Vendor_' + this.rebate.rebateProgram.vendorName + '_From_' + this.datePipe.transform(this.rebate.rebateProgram.startDate, 'MM_dd_YY') + '_To_' + this.datePipe.transform(this.rebate.rebateProgram.endDate, 'MM_dd_YY') );

    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE ='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE,});
    FileSaver.saveAs(
      data,
        //fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
       fileName + EXCEL_EXTENSION
    );
  }


  onFileChange(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      this.excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });


      var jsonString = JSON.stringify([...this.excelData]);
      //ExcelUpload
      //console.log(this.excelData);

      const fileData = new File([jsonString], 'filename.txt', { type: 'text/plain' })

      console.log(fileData);

        this.dataService.uploadRebateQuantityFile(
        this.rebate.rebateProgram.vendorId,
        this.rebate.rebateProgram.startDate,
        this.rebate.rebateProgram.endDate,
        fileData,
        this.view).subscribe((resp) => {
        this.item = resp.map((i) => new RebateLineItem(i));
        this.item.forEach((i) => this.validateRebate(i));
      });
    

    };
    let x = reader.readAsArrayBuffer(file);
  }



  saveFileAdditional(elem)
  {
    if (!this.rebate.rebateFilesSecondary)
    {
			this.rebate.rebateFilesSecondary = [];
		}

    const file = elem.target.files[0];

    if (this.auditFileName(file.name)) {
      this.rebate.rebateFilesSecondary.push(new RebateFile({ file: file, fileName: file.name, fileSize: file.size }));
      elem.target.value = null;
      this.errorMessage = [];

    }
    else
    {
      const invalidChars = /[#%&\{\}\\<>*?/$!'":@+`|=]/;
      this.errorMessage = [
        {
          severity: "error",
          summary: "Error",
          detail: "File name may not contain any of the following characters:" + invalidChars
        }
      ];
    }
	}


  saveFile(elem)
  {
    if (!this.rebate.rebateFiles)
    {
      this.rebate.rebateFiles = [];
    }

    const file = elem.target.files[0];

    if (this.auditFileName(file.name))
    {
      //Proceed with file upload
      this.rebate.rebateFiles.push(new RebateFile({ file: file, fileName: file.name, fileSize: file.size }));
      elem.target.value = null;

      this.errorMessage = [];
    }
    else
    {
      const invalidChars = /[#%&\{\}\\<>*?/$!'":@+`|=]/;

      this.errorMessage = [
        {
          severity: "error",
          summary: "Error",
          detail: "File name may not contain any of the following characters: " + invalidChars
        }  
      ];

    }
	}

	deleteFileFileAdditional(i) {
		this.rebate.rebateFilesSecondary.splice(i, 1);
	}

	deleteFile(i) {
		this.rebate.rebateFiles.splice(i, 1);
	}

	onGlobalFilter(table: Table, event: Event) {
		table.filterGlobal((event.target as HTMLInputElement).value, "contains");
	}

	submit() {
		this.rebateForm.markAllAsTouched();
		if (this.rebateForm.valid) {
      this.rebate.rebateForm = new RebateForm(this.rebateForm.value);
		  this.rebate.rebateLineItems = this.item;
			this.rebate.rebateLineItems = this.rebate.rebateLineItems.filter((i) => i.quantity > 0).map((i) => new RebateLineItem({ modelID: i.modelID, programDate: `${this.datePipe.transform(this.rebate.rebateProgram.startDate, "MMM dd")} - ${this.datePipe.transform(this.rebate.rebateProgram.endDate, "MMM dd, yyyy")}`, reimbursement: i.reimbursement, quantity: i.quantity, serial: i.serial, modelName: i.model }));


 
			//save files to send later
			const file = [...this.rebate.rebateFiles];		

      if (this.RequiresAdditionalUpload)
      {
      const filesSecondary = [...this.rebate.rebateFilesSecondary];
                this.rebate.rebateFilesSecondary.forEach((item) =>
                {
                  file.push(item);
               });
      }




			this.rebate.rebateFiles = this.rebate.rebateFiles.map((f) => new RebateFile({ fileName: f.fileName, fileSize: f.fileSize }));
			const { rebateProgram, ...entry } = this.rebate;

      this.dataService.submitRebate(entry).subscribe((resp: any) =>
      {
        this.rebate.claimId = resp.data.claimId;

				this.dataService
					.submitRebateFiles(
						resp.data.claimId,
            file.map((f) => f.file)
					)
					.subscribe((resp) => {
						this.store.dispatch(new SuccessMessageSet("Your rebate has been successfully submitted"));
						this.onSubmit.next(this.rebate);
					});
			});
		}
	}

    private auditFileName(fileName: string): boolean {
    const invalidChars =  /[#%&\{\}\\<>*?/$!'":@+`|=]/;
    return !invalidChars.test(fileName);
  }

  uploadQuantities(e) {
   
      this.dataService.uploadRebateQuantityFile(this.rebate.rebateProgram.vendorId, this.rebate.rebateProgram.startDate, this.rebate.rebateProgram.endDate, e.srcElement.files[0], this.view).subscribe((resp) => {
      this.item = resp.map((i) => new RebateLineItem(i)); 
			this.item.forEach((i) => this.validateRebate(i));
    });
	}
}
