import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Address } from "src/app/models/account/address";

@Component({
	selector: "app-add-edit-address",
	templateUrl: "./add-edit-address.component.html",
	styleUrls: ["./add-edit-address.component.scss"],
})
export class AddEditAddressComponent implements OnInit {
	@Input() address: Address;
	@Output() onCancel: EventEmitter<boolean> = new EventEmitter();
	@Output() onSubmit: EventEmitter<Address> = new EventEmitter();

	public form: UntypedFormGroup;
	public addressTypeOptions = [
		{label: 'Store', value: 4},
		{label: 'User Private', value: 9},
		{label: 'Other', value: 3},
		{label: 'Shipping', value: 2},
	]
	constructor(private fb: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			address1: [ this.address.address1, Validators.required ],
			address2: [ this.address.address2 ],
			city: [ this.address.city, Validators.required ],
			state: [ this.address.state, Validators.required ],
			zip: [ this.address.zip, Validators.required ],
			country: [ this.address.country, Validators.required ],
			addressId: [ this.address.addressId ],
			isActive: [ 1, Validators.required ],
			addressTypeId: [ this.address.addressTypeId, Validators.required ]
		})
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes.address) {
			this.form.patchValue(this.address);
		}
	}

	cancel() {
		this.onCancel.next(true);
	}
	submit() {
		this.form.markAllAsTouched();
		if(this.form.valid) {
			this.onSubmit.next(new Address({ ...this.address, ...this.form.value }))
		}
	}
}
