import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Address } from "../models/account/address";
import { InvoiceHistory, InvoiceHistoryItem } from "../models/accounting/invoice-history";
import { MemberSummary } from "../models/accounting/member-summary-data";
import { Backorder } from "../models/dashboard/backorder";
import { BillingOption } from "../models/dashboard/billing-options";
import { Company, BRM } from "../models/dashboard/company";
import { EzPay, EzPaySavings } from "../models/dashboard/ez-pay";
import { MostFrequentlyBought } from "../models/dashboard/most-frequently-bought";
import { OrderHistoryDetails } from "../models/dashboard/order-history-details";
import { OrderHistoryItem } from "../models/dashboard/order-history-item";
import { OrderSearchCriteria } from "../models/dashboard/order-search-criteria";
import { Owner } from "../models/dashboard/owner";
import { Permission } from "../models/dashboard/permission";
import { CompanyPointsUserData, PointsUserInfo, PointsPurchase, PointsOrders } from "../models/dashboard/points";
import { PriceProtection, PriceProtectionDetail, PriceProtectionEntry, PriceProtectionProgram, Rebate, RebateDetail, RebateEntry, RebateValidation, RebateVendor } from "../models/dashboard/rebates";
import { Sar, SarClaim, SarPrint } from "../models/dashboard/sar";
import { Subscription } from "../models/dashboard/subscriptions";
import { User } from "../models/dashboard/user";
import { RecentForumPost } from "../models/forum/recent-forum-post";
import { SearchCriteria, SearchResults, POSearchResult, ShipSearchResult } from "../models/search/search-results";
import { Brand } from "../models/shopping/brand";
import { MixMatch, MixMatchInvalid } from "../models/shopping/mix-match";
import { Order } from "../models/shopping/order";
import { OrderSubmission } from "../models/shopping/order-submission";
import { Bundle, Product, ProductFile, ProductResponse } from "../models/shopping/product";
import { ShippingCostInfo } from "../models/shopping/shipping-cost-info";
import { Special } from "../models/shopping/specials";
import { Vendor } from "../models/vendor/vendor";
import { ApiService } from "./api.service";
import { ShippingError, ShippingProductList } from "../models/dashboard/shipping-error";
import { StolenGoodsReport } from "../models/dashboard/stolen-goods-report";
import { SellThrough, SellThroughItem } from "../models/dashboard/sell-through";
import { reportDiscontinued} from "../models/Reports/Report";
import { Observable } from "rxjs";

@Injectable()
export class DataService {

	constructor(private api: ApiService) {}

	getRandom(count) {
		return this.api.get('/api/shopping/random/'+count);
	}

	login(username: string, password: string) {
		return this.api.post(`/api/auth`, { username, password });
  }

	logInAs(userId: number) {
		return this.api.get(`/api/auth/loginas/${userId}`);
	}
	forgotPassword(usernameOrEmail: string) {
		return this.api.post('/api/auth/forgot-password', usernameOrEmail);
	}
	verifyForgotPasswordLink(code: string) {
		return this.api.get(`/api/auth/verify/${code}`)
	}
	resetPassword(code: string, password: string) {
		return this.api.post('/api/auth/reset', { code, password });
	}
	getEzPayStatus() {
		return this.api.get<number>('/api/accounting/EZPayMenu');
	}
	getCart() {
		return this.api.get('/api/shopping/cart');
	}
	getShippingCost() {
		return this.api.get<Array<ShippingCostInfo>>('/api/shopping/shippingcosts');
	}
	addToCart(productCode: string, qty: number) {
		return this.api.put(`/api/shopping/cart/${productCode}/${qty}`, {});
	}
	addMultipleToCart(items: Array<{productCode: string, quantity: number }>) {
		return this.api.post('/api/shopping/cart/add/multiple', items);
	}
	addBundleToCart(bundleCode: string, quantity: number) {
		return this.api.put(`/api/shopping/bundle/${bundleCode}/${quantity}`, {})
	}
	updateCartItem(updateData: {cartItemId: number, quantity: number, rejectUpsell: boolean}) {
		return this.api.post(`/api/shopping/cart/item`, updateData);
	}
	updateCartBundle(cartBundleId: number, qty: number) {
		return this.api.post(`/api/shopping/cart/bundle/${cartBundleId}/${qty}`, {});
	}
	clearCart() {
		return this.api.post('api/shopping/clear-cart', {});
	}
	sortCart(items: Array<{cartItemId: number, sortId: number}>) {
		return this.api.post('api/shopping/cart/sort', items);
	}
	uploadOrderFile(type, removeText: string, file: File) {
		const formData: FormData = new FormData();
		//formData.append("removeText", removeText);
		formData.append(`file`, file, file.name);
		return this.api.postBlob(`api/shopping/order/upload/${type}/${removeText}`, formData)
  }

  determineSpecialOrder() {
    return this.api.get('/api/shopping/specialOrder');
  }

	getDropShipShippingCharge() {
		return this.api.get('/api/shopping/drop-ship/shipping-cost');
  }

	submitOrder(submission: OrderSubmission) {
		return this.api.post('/api/shopping/order/submit', submission);
	}
	getCategories() {
		return this.api.get('/api/shopping/category');
	}
	getCategoryProducts(catId: number) {
		return this.api.get(`/api/shopping/category/${catId}`);
	}
	getNestedCategories() {
		return this.api.get('/api/shopping/category/tree');
	}
	getBrands() {
		return this.api.get<Array<Brand>>('/api/shopping/brand');
	}
	getBrand(brandId) {
		return this.api.get(`/api/shopping/brand/${brandId}`);
	}
	getBrandProducts(brandId: number) {
		return this.api.get(`/api/shopping/brand/${brandId}/products`);
	}
	getProduct(productCode: string) {
		return this.api.get<ProductResponse>(`/api/shopping/product/${productCode}`);
	}
	getTechSpecs(productCode: string) {
		return this.api.get(`/api/shopping/product/${productCode}/specs`);
	}
	getProductFiles(productCode: string) {
		return this.api.get<Array<ProductFile>>(`/api/product/productFiles/${productCode}`)
	}
	getRelatedProducts(productCode: string) {
		return this.api.get<Array<Product>>(`/api/shopping/product/${productCode}/related`);
	}
	getBundle(bundleId: string) {
		return this.api.get<Bundle>(`api/shopping/bundle/${bundleId}`)
	}

	getMixMatch(id: number) {
		return this.api.get<MixMatch>(`/api/shopping/mixmatch/${id}`);
	}
	getMixMatchInvalid() {
		return this.api.get<Array<MixMatchInvalid>>('api/shopping/mixmatch/invalid');
	}
	getAccountAddress() {
		return this.api.get('api/account/getaddress');
	}
	getAddresses(addressTypeId: number) {
		return this.api.get<Array<Address>>(`/api/account/addresses/${addressTypeId}`);
	}

	editAddress(address: Address) {
		return this.api.post('/api/dashboard/address', address);
	}
	addAddress(address: Address) {
		return this.api.put('/api/dashboard/address', address);
	}
	deleteAddress(address: Address) {
		return this.api.delete('/api/dashboard/address', address.addressId);
	}
	restoreAddress(addressId: number) {
		return this.api.post('/api/dashboard/address/restore', addressId);
	}
	getUsers() {
		return this.api.get<Array<User>>('/api/account/users');
	}
	getUsersById(userIds: Array<number>) {
		return this.api.post<Array<User>>('api/account/users/ids', userIds);
	}
	addUser(user: User) {
		return this.api.put('/api/dashboard/user', user);
	}
	editUser(user: User) {
		return this.api.post('/api/dashboard/user', user);
	}
	deleteUser(userId: number) {
		return this.api.delete('/api/dashboard/user', userId)
	}
	restoreUser(userId: number) {
		return this.api.post('/api/dashboard/user/restore', userId);
	}

  searchProductsByCode(productCode: string, location: string)
  {
    return this.api.get<Array<Product>>(`/api/product/search/code/${productCode}/${location}`);
    }


  //This is where we will change the model.
  searchShipping(SearchData: string) {
    return this.api.get<Array<ShipSearchResult>>(`/api/Retailer/search/PO/${SearchData}`);
  }

  searchShippingProds(ShippingNumber: string) {
    return this.api.get<Array<ShippingProductList>>(`/api/Retailer/search/ShippingErrorProds/${ShippingNumber}`);
  }


	  getMembers() {
		  return this.api.get<Array<Company>>('/api/account/getroster');
	  }

  getMembersZip(zip: string, Distance: number ) {
    return this.api.get<Array<Company>>(`/api/account/getrosterZip/${zip}/${Distance}`);
      }


    downloadInvoice(id) {
		return this.api.getBlob(`api/filedownload/invoice/${id}`);
    }
	downloadInvoiceFromHistory(invoice) {
		return this.api.postGetBlob(`api/filedownload/invoice/`, invoice);
	}


 

	getShippingErrors() {
		return this.api.get("/api/dashboard/shipping-errors");
	}
	getShippingErrorDetails(id) {
		return this.api.get(`/api/dashboard/shipping-error/${id}`);
	}
	saveShippingError(err: ShippingError) {
		return this.api.post('api/dashboard/shipping-error', err);
	}
	saveShippingErrorFiles(id: number, files) {
		const formData: FormData = new FormData();
		for(let f of files) {
			formData.append(f.name, f, f.name);
		};
		return this.api.postBlob(`api/dashboard/shipping-error/${id}`, formData);
  }


  /******* Consolidates all invoices  ********/
    downloadEZPAY() {
     return this.api.getBlob(`api/filedownload/ConsolidateEZPAY`);
    }

    downloadPayables() {
    return this.api.getBlob(`api/filedownload/ConsolidatePayables`);
    }

	reprintInvoices(id) {
		return this.api.getBlob(`api/filedownload/reprint/${id}`)
	}


	/******* SPECIALS ********/
	getSpecialsForCart() {
		return this.api.get('/api/specials/cart');
	}
	getSpecialsForProduct(id) {
		return this.api.get<Array<Special>>(`/api/product/specials/${id}`)
	}

	/************* ACCOUNTING *************/
	getEzpay() {
		return this.api.get<Array<EzPay>>('/api/billing/billing');
	}
	getEzpaySavings() {
		return this.api.get<Array<EzPaySavings>>('/api/billing/savings');
	}
	getEzpayReceipt(receiptNumber) {
		return this.api.get<Array<EzPay>>(`/api/billing/receipt${receiptNumber ? '/' + receiptNumber : ''}`);
	}
	getEzpayHistorical() {
		return this.api.get<Array<EzPay>>('/api/billing/historical');
	}
	getEzpayHistoricalDetail(receipt: string) {
		return this.api.get<Array<EzPay>>(`/api/billing/historical/${receipt}`)
	}
	searchEzPay(term) {
		return this.api.post('/api/billing/Searchezpay', { term })
	}
	payEzPay(invoices: Array<{Invoice: string, DeductionDate: Date}>) {
		return this.api.post('api/billing/pay', invoices);
	}
	getEzPayEnrollmentStatus() {
		return this.api.get('api/accounting/EZPayEnrollmentStatus');
	}
	saveEzPayEnrollmentStatus(status: number) {
		return this.api.post('api/accounting/EZPayEnrollmentStatus', status);
	}

  getStatementSummary(SearchCriteria: string) {
      return this.api.get(`api/accounting/StatementSummary/${SearchCriteria}`);
  }

  getStatementHistory() {
    return this.api.get(`api/accounting/StatementHistory`);
  }

	getMemberSummary() {
		return this.api.get<MemberSummary>(`api/accounting/MemberSummary`);
	}
	getShippedSummary() {
		return this.api.get(`api/accounting/Shipped`);
	}
	getBackordersSummary() {
		return this.api.get(`api/accounting/Backorders`);
	}

	getInvoiceHistory() {
		return this.api.get<Array<InvoiceHistoryItem>>('api/accounting/GetInvoiceHistory').pipe(
			map(i => i.map(item => new InvoiceHistoryItem(item))),
			map(i => new InvoiceHistory(i))
		);
	}
	getOrderHistory(orderStatus: number | '' = '', orderType: number | '' = '', startDate: string = '', endDate: string = '') {
		return this.api.get<Array<OrderHistoryItem>>(`api/retailer/orders?orderStatus=${orderStatus || ''}&orderType=${orderType || ''}&startDate=${startDate || ''}&endDate=${endDate || ''}`);
	}
	getOrderHistoryDetails(orderId: number) {
		return this.api.get<OrderHistoryDetails>(`api/retailer/orders/${orderId}`);
	}

	uploadRebateFiles(vendorId: number, files: {files: Array<File> }) {
		const formData: FormData = new FormData();
		formData.append('vendorID', String(vendorId));
		let idx = 1;
		for(let f of files.files) {
			formData.append(`file_${idx++}`, f, f.name);
		};
		return this.api.postBlob('api/rebates/upload', formData)
	}

	getRebate(orderId:string) {
		return this.api.get<RebateEntry>(`api/rebates/rebate/${orderId}`)
	}
	getPriceProtection(orderId:string) {
		return this.api.get<RebateEntry>(`api/rebates/price-protection/${orderId}`);
	}
	uploadRebateQuantityFile(vendorId: number, startDate: any, endDate: any, file: File) {
		const formData: FormData = new FormData();
		formData.append('vendorId', String(vendorId));
		formData.append('startDate', startDate);
		formData.append('endDate', endDate);
		formData.append(`file`, file, file.name);
		return this.api.postBlob(`api/rebates/upload/quantities`, formData)
  }


	/********* SAR **************/
	getSars() {
		return this.api.get<Array<Sar>>('api/SARS');
	}
	getSarDetail(sarId: number) {
		return this.api.get(`api/SARS/${sarId}`);
	}
	submitSar(sar: SarClaim) {
		return this.api.post('api/SARS', sar);
	}
	searchSars(term: string) {
		return this.api.post('api/sars/searchsars', { term });
	}
	getSarPrint(sarId) {
		return this.api.get<SarPrint>(`api/sars/print/${sarId}`);
	}

	/********* Vendor **************/
	searchVendorsByName(term: string) {
		return this.api.get(`api/Vendor/search/${term}`);
	}
	getVendor(id: number) {
		return this.api.get<Vendor>(`api/vendor/${id}`);
	}
	getAllVendors() {
		return this.api.get<Array<Vendor>>('api/vendor');
	}

	/********* Brands **************/
	searchBrandsByName(term: string) {
		return this.api.get(`api/Brand/search/${term}`);
	}


	/********* Sell Through **************/
	getSellThroughProducts() {
		return this.api.get<Array<SellThroughItem>>('api/product/sell-through');
	}
	submitSellThrough(sellThrough: SellThrough) {
		return this.api.post('api/product/sell-through', sellThrough)
	}
	uploadSellThrough(date, file: File) {
		const formData: FormData = new FormData();
		formData.append('date', date);
		formData.append(`file`, file, file.name);
		return this.api.postBlob(`api/product/sell-through/upload`, formData)
	}
	getSellThroughHistory() {
		return this.api.get<Array<SellThroughItem>>('api/product/sell-throughHistorical');
	}
	/********* Forum **************/
	getRecentForumPosts(count?: number) {
		if(count) {
			return this.api.get<Array<RecentForumPost>>(`api/forum/${count}`).pipe(map(p => p.map(i => new RecentForumPost(i))));
		}
		return this.api.get<Array<RecentForumPost>>(`api/forum`).pipe(map(p => p.map(i => new RecentForumPost(i))));
	}

	search(critieria: SearchCriteria) {
		return this.api.post<SearchResults>("/api/search", critieria).pipe(
			map(r => new SearchResults(r))
		);
	}

	warehouseSearch(criteria) {
		return this.api.post('api/search/warehouse', criteria)
  }

 

	quickSearch(typeId, term) {
		return this.api.get(`api/search/quick/${typeId}/${term}`)
	}

	/*********Stolen Goods Reports *********/
	getStolenGoodsReports() {
		return this.api.get(`api/stolengoods`);
	}
	getStolenGoodsReportsFlat() {
		return this.api.get(`api/stolengoods/flat`);
	}
	getStolenGoodsDetails(id) {
		return this.api.get(`api/stolengoods/${id}`);
	}
	submitStolenGoodsReport(report: StolenGoodsReport) {
		return this.api.post('api/stolengoods', report);
	}
	searchStolenGoods(term: string) {
		return this.api.get(`api/stolengoods/search/${term}`)
	}
	uploadStolenGoodsReports(file: File) {
		const formData: FormData = new FormData();
		formData.append(`file`, file, file.name);
		return this.api.postBlob(`api/stolengoods/upload`, formData)
	}

	/********** Specials **********/
	getSpecials() {
		return this.api.get<Array<Special>>('api/specials');
	}
	getSpecialDetails(specialId) {
		return this.api.get<Special>(`api/specials/${specialId}`);
	}

	/********** Dashboard **********/
	searchDashboard(term: string) {
		return this.api.post('api/search/dashsearch', { SearchTerm: term })
  }

	getSubscriptions() {
		return this.api.get<Array<Subscription>>('api/dashboard/subscription');
	}
	subscribe(userId: number, subscriptionTypeId: number, quantity: number) {
		return this.api.put('api/dashboard/subscription', {userId, subscriptionTypeId, quantity})
	}
	unsubscribe(subscriptionId: number) {
		return this.api.delete(`api/dashboard/subscription`, subscriptionId);
	}
	addSubscription(userId: number, subscriptionTypeId: number, quantity: number) {
		return this.api.put(`api/dashboard/addsubscriber/${userId}/${subscriptionTypeId}/${quantity}`, {})
	}

	getBillingOptions() {
		return this.api.get<Array<BillingOption>>('api/dashboard/billing-options')
	}
	saveBillingOption(optionId, year) {
		return this.api.post(`api/dashboard/billing-options/${optionId}/${year}`, {});
	}
	getMostFrequentlyBought() {
		return this.api.get<Array<MostFrequentlyBought>>('api/dashboard/frequentbuy');
	}

	getOrderOverview() {
		return this.api.get<Array<Order>>('api/retailer/overvieworder');
	}
	getOrderDetails(orderId) {
		return this.api.get<Order>(`api/retailer/orders/${orderId}`);
	}
	searchOrderHistory(criteria: OrderSearchCriteria) {
		return this.api.post('/api/retailer/searchOrders/', criteria)
	}
	getBackorders() {
		return this.api.get<Array<Backorder>>('api/retailer/backorders');
	}

	getLegalContact() {
		return this.api.get<User>('api/dashboard/LegalContact');
	}

	getCompanyData() {
		return this.api.get<Company>('api/dashboard/CompanyData');
	}

	GetBRM() {
		return this.api.get<BRM>('api/dashboard/BRM');
	}

	editCompanyData(data: Company) {
		return this.api.post('api/dashboard/CompanyData', data);
	}

	getRebateHistory() {
		return this.api.get<Rebate>('api/rebates/history');
	}
	getRebateHistoryDetails(orderId) {
		return this.api.get<RebateDetail>(`api/rebates/history/${orderId}`);
	}
	getPriceProtectionHistory() {
		return this.api.get<PriceProtection>('api/rebates/price-protection');
	}
	getPriceProtectionDetail(orderId) {
		return this.api.get<PriceProtectionDetail>(`api/rebates/price-protection/detail/${orderId}`);
	}
	
	searchRebates(term) {
		return this.api.post('/api/rebates/SearchRebates', { term });
	}
	getRebatePrograms() {
		return this.api.get<Array<RebateVendor>>('api/rebates/programs');
	}
	getRebateProgramProducts(vendorId: number, startDate: Date, endDate: Date) {
		return this.api.post('api/rebates/details', { vendorId, startDate, endDate });
	}
	validateRebate(modelId: number, quantity: number) {
		return this.api.get<RebateValidation>(`api/rebates/validate/${modelId}/${quantity}`);
	}
	submitRebate(entry: RebateEntry) {
		return this.api.post('api/rebates/submit', entry);
	}
	getPriceProtectionPrograms() {
		return this.api.get<Array<PriceProtectionProgram>>('api/rebates/priceprograms');
	}
	getPriceProtectionDetails(vendorId: number, programDate: Date) {
		return this.api.post('api/rebates/pricedetails', { vendorId, programDate })
	}
	submitPriceProtection(entry: PriceProtectionEntry) {
    return this.api.post('/api/rebates/submitPrice',
      { rebateLineItems: entry.priceProtectionLineItems.map(i => ({ ...i, serials: i.serials.map(s => s.number) })), rebateFiles: entry.priceProtectionFiles, rebateForm: entry.priceProtectionForm })
	}
	submitRebateFiles(rebateId, files: Array<File>)
		{
		const formData: FormData = new FormData();
		formData.append('RebateId', String(rebateId));
		let idx = 1;
		for(let f of files) {
			formData.append(`file_${idx++}`, f, f.name);
	};

		return this.api.postBlob('api/rebates/submit/files', formData)
	}

	getAllPermissions() {
		return this.api.get<Array<Permission>>('api/dashboard/permissions');
	}
	getUserPermissions(userId: number) {
		return this.api.get<Array<Permission>>(`api/dashboard/permissions/user/${userId}`);
	}
	setUserPermissions(userId, permissionIds) {
		return this.api.post(`api/dashboard/permissions/user/${userId}`, permissionIds);
	}

    getPointsPurchases(){
      return this.api.get <Array<PointsPurchase>>('api/dashboard/GetPointsPurchases');
    }

  submitPointsApproval(pointsOrdersList: Array<PointsOrders>) {
      return this.api.post('/api/dashboard/ApprovePointsPurchases', pointsOrdersList)
    }

    getPointsPurchaseByOrderId(orderId: number) {
      return this.api.get<PointsUserInfo>(`api/dashboard/points/report/${orderId}`)
    }

	getPointsUsers() {
		return this.api.get<Array<User>>('api/dashboard/GetPointsUsers');
  }
	sendPointsInvites(invitees: Array<string>) {
		return this.api.post('api/dashboard/sendPointsInvites', invitees)
  }
	deletePointsUser(userId) {
		return this.api.delete("api/dashboard/points", userId)
  }

	getUserPointsReport(userId) {
		return this.api.get<PointsUserInfo>(`api/dashboard/points/report/${userId}`)
  }


	getPointsCompanyData() {
		return this.api.get<Array<CompanyPointsUserData>>(`api/dashboard/points/report`)
	}

	getOwners() {
		return this.api.get<Array<Owner>>('api/dashboard/owners')
  }


  /******* Reports ********/
  getDiscontinued() {
    return this.api.get<Array<reportDiscontinued>>('/api/Reports/getDiscontinued');
  }

}


