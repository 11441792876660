import { Component, OnDestroy, OnInit, Sanitizer, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Store } from "@ngrx/store";
import { of, Subject } from "rxjs";
import { filter, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { User } from "src/app/models/dashboard/user";
import { ProUser } from "src/app/models/pro-user";
import { Article } from "src/app/models/strapi/article";
import { Comment } from "src/app/models/strapi/comment";
import { Permissions } from "src/app/modules/controls/enums/permissions";
import { DataService } from "src/app/services/data.service";
import { StrapiService } from "src/app/services/strapi.service";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-article",
	templateUrl: "./article.component.html",
	styleUrls: ["./article.component.scss"],
})

export class ArticleComponent implements OnInit, OnDestroy {
	public article: Article;
	public comments: Array<Comment>;
	public showComments: boolean = false;

	private user: ProUser;
	private destroy$: Subject<boolean> = new Subject();
	public permissions = Permissions;
	private likers: Array<User> = [];
	constructor(private strapiService: StrapiService, private dataService: DataService, private route: ActivatedRoute, private store: Store<IAppState>, private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.store
			.select(selectUser)
			.pipe(takeUntil(this.destroy$))
			.subscribe((u) => (this.user = u));
		this.route.paramMap
			.pipe(
				filter((p) => p.get("id") != null),
				map((p: ParamMap) => p.get("id")),
				switchMap((id: string) => this.strapiService.getArticle(parseInt(id))),
				tap((a) => (this.article = { ...a, contentSafeHtml: this.sanitizer.bypassSecurityTrustHtml(a.content) })),
				switchMap((a: Article) => {if(a.enableComments) { return this.strapiService.getArticleComments(a.id) } else { return of(null) }}),
				tap((c) => (this.comments = c)),
				switchMap(() => this.strapiService.getLikers(this.article.id)),
				tap((ids:any) => this.dataService.getUsersById(ids).subscribe(l => this.likers = l.map(u => new User(u)).sort(this.getSortFunction(this.user.userId)))),
				switchMap(() => this.strapiService.getArticleInteractionCountsSingle(this.article.id, this.user.userId))
			)
			.subscribe((n: any) => {
				this.article.commentCount = n.commentCount;
				this.article.userCommented = n.userCommented;
				this.article.likeCount = n.likeCount;
				this.article.userLiked = n.userLiked;
				this.article.views = n.views;
				this.strapiService.logArticleView(this.article.id, this.user.userId).subscribe();
			});
	}
	addComment(comment: Comment) {
		this.comments.push(comment);
	}

	toggleLike() {
		if (this.article.userLiked) {
			this.strapiService.unlike(this.article.id, "article", this.user.userId).subscribe(() => {
				this.article.likeCount -= 1;
				this.article.userLiked = false;
				this.likers = this.likers.filter(l => l.userId != this.user.userId).sort(this.getSortFunction(this.user.userId));
			}); 
		} else {
			this.strapiService.like(this.article.id, "article", this.user.userId).subscribe(() => {
				this.article.likeCount += 1;
				this.article.userLiked = true;
				this.likers.push(new User({ firstName: this.user.name.split(' ')[0], lastName: this.user.name.split(' ')[1], userId: this.user.userId }));
				this.likers.sort(this.getSortFunction(this.user.userId));
			});
		}
	}

	getSortFunction(userId) {
		return (a,b) => {
			if(a.userId == userId) return -1;
			if(b.userId == userId) return 1;
			return 0;
		}
	}

	goToComments() {
		setTimeout(() => document.getElementById('commentSection').scrollIntoView(), 100);
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
