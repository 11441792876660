import { Component, Input, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() url: string = 'https://members.promaster.com';
  @Inject(MAT_DIALOG_DATA) data: any;
  safeUrl: SafeResourceUrl = '';

  // public iframeUrl: SafeResourceUrl;
  constructor(private sanitizer: DomSanitizer) {  }

  ngOnInit() {
    console.log('Data received:', this.data);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  closeModal() {
   
    }
}
