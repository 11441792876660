import { Action } from "@ngrx/store";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { UndoMessage } from "src/app/models/dashboard/undo-message";
import { TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";



export enum SiteActionTypes {
	SUCCESS_MESSAGE_SET = '[SITE] Success Set',
	SUCCESS_MESSAGE_CLEAR = '[SITE] Success Clear',
	ERROR_MESSAGE_SET = '[SITE] Error Set',
	ERROR_MESSAGE_CLEAR = '[SITE] Error Clear',
	ALL_MESSAGE_CLEAR = '[SITE] All Clear',
	OPEN_SEARCH = '[SITE] Open Search',
	CLOSE_SEARCH = '[SITE] Close Search',
	OPEN_SIDE_NAV = '[SITE] Open Side Nav',
	CLOSE_SIDE_NAV = '[SITE] Close Side Nav',
	OPEN_VENDOR_PREVIEW = '[SITE] Open Vendor Preview',
	CLOSE_VENDOR_PREVIEW = '[SITE] Close Vendor Preview',
	SET_NAV_MENU_OPEN = '[SITE] Set Nav Menu Open',
	SET_DASHBOARD_TITLE = '[SITE] Set Dashboard Title',
	SET_DASHBOARD_HEADER_BUTTONS = "[SITE] Set Dashboard Header Buttons",
	NAVIGATING = '[SITE] Navigation Start',
	SHOW_DOWNLOAD_DIALOG = '[SITE] Show Download Dialog',
	SET_UNDO_MESSAGE = '[SITE] Set Undo Message',
	SHOW_UPLOAD_ORDER = '[SITE] Show Upload Order',
	HIDE_UPLOAD_ORDER = '[SITE] Hide Upload Order',
	OPEN_QUICK_ADD = '[SITE] Open Quick Add',
  CLOSE_QUICK_ADD = '[SITE] Close Quick Add',
}


export class SuccessMessageSet implements Action {
	public readonly type = SiteActionTypes.SUCCESS_MESSAGE_SET;
	constructor(public message: string) {}
}
export class SuccessMessageClear implements Action {
	public readonly type = SiteActionTypes.SUCCESS_MESSAGE_CLEAR;
	constructor() {}
}
export class ErrorMessageSet implements Action {
	public readonly type = SiteActionTypes.ERROR_MESSAGE_SET;
	constructor(public message: string) {}
}
export class ErrorMessageClear implements Action {
	public readonly type = SiteActionTypes.ERROR_MESSAGE_CLEAR;
	constructor() {}
}
export class AllMessagesClear implements Action {
	public readonly type = SiteActionTypes.ALL_MESSAGE_CLEAR;
	constructor() {}
}

export class OpenSearch implements Action {
  public readonly type = SiteActionTypes.OPEN_SEARCH;
  constructor(public headerSearchType )
  {}
 
}

export class CloseSearch implements Action {
	public readonly type = SiteActionTypes.CLOSE_SEARCH;
	constructor() {}
}
export class OpenSideNav implements Action {
	public readonly type = SiteActionTypes.OPEN_SIDE_NAV;
	constructor() {}
}
export class CloseSideNav implements Action {
	public readonly type = SiteActionTypes.CLOSE_SIDE_NAV;
	constructor() {}
}export class OpenVendorPreview implements Action {
	public readonly type = SiteActionTypes.OPEN_VENDOR_PREVIEW;
	constructor() {}
}
export class CloseVendorPreview implements Action {
	public readonly type = SiteActionTypes.CLOSE_VENDOR_PREVIEW;
	constructor() {}
}

export class SetNavMenuOpen implements Action {
	public readonly type = SiteActionTypes.SET_NAV_MENU_OPEN;
	constructor(public menu: string) {}
}
export class SetDashboardTitle implements Action {
	public readonly type = SiteActionTypes.SET_DASHBOARD_TITLE;
	constructor(public title: string) {}
}

export class SetDashboardHeaderButtons implements Action {
	public readonly type = SiteActionTypes.SET_DASHBOARD_HEADER_BUTTONS;
	constructor(public buttons: Array<DashboardHeaderButton>) {}
}

export class Navigating implements Action {
	public readonly type = SiteActionTypes.NAVIGATING;
	constructor() {}
}
export class ShowDownloadDialog implements Action {
	public readonly type = SiteActionTypes.SHOW_DOWNLOAD_DIALOG;
	constructor(public downloadData: Array<any>, public downloadTableInfo: TableInfo, public downloadHeaders: Array<{key: string, value: any}>, public downloadFormats: Array<string> = [ 'xls', 'csv', 'pdf']) {}
}

export class SetUndoMessage implements Action {
	public readonly type = SiteActionTypes.SET_UNDO_MESSAGE;
	constructor(public undoMessage: UndoMessage) {}
}
export class ShowUploadOrder implements Action {
	public readonly type = SiteActionTypes.SHOW_UPLOAD_ORDER;
	constructor() {}
}
export class HideUploadOrder implements Action {
	public readonly type = SiteActionTypes.HIDE_UPLOAD_ORDER;
	constructor() {}
}
export class OpenQuickAdd implements Action {
	public readonly type = SiteActionTypes.OPEN_QUICK_ADD;
	constructor() {}
}
export class CloseQuickAdd implements Action {
	public readonly type = SiteActionTypes.CLOSE_QUICK_ADD;
	constructor() {}
}


export type SiteActions = 
	SuccessMessageSet |
	SuccessMessageClear |
	ErrorMessageSet |
	ErrorMessageClear | 
	AllMessagesClear |
	OpenSearch |
	CloseSearch |
	OpenSideNav | 
	CloseSideNav |
	OpenVendorPreview | 
	CloseVendorPreview |
	SetNavMenuOpen |
	SetDashboardTitle |
	SetDashboardHeaderButtons |
	Navigating |
	ShowDownloadDialog |
	SetUndoMessage |
	ShowUploadOrder | 
	HideUploadOrder |
	OpenQuickAdd | 
	CloseQuickAdd 
;


