import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DialogService } from "primeng/dynamicdialog";
import { RebateEntry, RebateVendor } from "src/app/models/dashboard/rebates";
import { DataService } from "src/app/services/data.service";
import { SetDashboardTitle } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";
import { RebateClaimSummaryComponent } from "./rebate-claim-summary/rebate-claim-summary.component";

@Component({
	selector: "app-rebate-claim",
	templateUrl: "./rebate-claim.component.html",
	styleUrls: ["./rebate-claim.component.scss"],
})
export class RebateClaimComponent implements OnInit {
	public rebateVendors: Array<RebateVendor>;
	public sidebarVisible: boolean = false;
  public activeRebate: RebateEntry;
  public view: string = "Brand";
  
  constructor(private store: Store<IAppState>, private dataService: DataService, private dialogService: DialogService) {}

	ngOnInit(): void {
    this.store.dispatch(new SetDashboardTitle("Instant Rebate Claim"));
    this.dataService.getRebatePrograms("Brand").subscribe((v) => (this.rebateVendors = v));
	}

  Brand() {
    this.dataService.getRebatePrograms("Brand").subscribe((v) => (this.rebateVendors = v));
    this.view = "Brand"
  }

  Vendor() {
    this.dataService.getRebatePrograms("Vendor").subscribe((v) => (this.rebateVendors = v));
    this.view = "Vendor"
  }


  showRebateForm(rebateProgram, vendorId, vendorName) {

    let entry = new RebateEntry();
    let view = this.view;
    entry.rebateProgram = { ...rebateProgram, vendorId, vendorName, view };

 
		this.activeRebate = entry;
		this.sidebarVisible = true;
	}
	onSubmit(rebate) {
		this.sidebarVisible = false;
		document.getElementsByClassName('p-sidebar-mask')[0]?.remove();
		this.dialogService.open(RebateClaimSummaryComponent, { header: 'Rebate Summary', data: { rebate }})
	}
}
