<div class="pane">
	<div class="lists">
		<div class="list" *ngFor="let vendor of rebateVendors">
			<div class="header">{{vendor.vendorName}}</div>
			<ul class="programs" *ngIf="vendor.programs">
				<li class="program" *ngFor="let program of vendor.programs" (click)="showRebateForm(program, vendor.vendorId, vendor.vendorName)">
					<div class="start">{{program.startDate | date: 'MMMM dd, yyyy'}}</div>
					<div class="end">{{program.endDate | date: 'MMMM dd, yyyy'}}</div>
				</li>
			</ul>
		</div>
	</div>
</div>



<p-sidebar [(visible)]="sidebarVisible" position="right" (onHide)="activeRebate = null" [style]="{width: '90vw'}">
	<app-rebate-claim-form *ngIf="activeRebate" [rebate]="activeRebate" (onSubmit)="onSubmit($event)"></app-rebate-claim-form>
</p-sidebar>
