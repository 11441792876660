import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from "@angular/core";
import { CardContent } from "src/app/models/training/card-content";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalComponent } from '../modal/modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';



@Component({
  selector: 'app-training-library',
  templateUrl: './training-library.component.html',
  styleUrls: ['./training-library.component.scss']
})
export class TrainingLibraryComponent implements OnInit
{
  public iframeUrl: string = '';
  public cards: CardContent[] = [];



  constructor(public dialog: MatDialog) {}

  ngOnInit(): void
  {


    this.cards.push({
      title: "EZ-Pay",
     // description: "<ul><li>Making a Payment</li><li > Selecting Specific Invoices < /li>< li > Metrics and Savings < /li>< li > Accessing Receipts and Invoices < /li>< li > Print Invoices < /li>< li > Past Payment Records < /li> < li > Enroll in Auto EZ - PAY < /li>< /ul>",
      //imageUrl: "../../../../../assets/images/EZPAY.jpg",
      description: "Coming Soon",
      imageUrl: "../../../../../assets/images/Comingsoon.png",
     // subTitle: "EZ-PAY is a comprehensive payment management system designed for convenience and efficiency, allowing users to streamline payment processes and track their financial activities.",
      subTitle: "Coming Soon",
      content: "OPOPOPOP"
    })

    this.cards.push({
      title: "Rebates",
      //description: "Many new features to learn about.<BR><BR> ",
      description: "Coming Soon",
      //imageUrl: "../../../../../assets/images/Rebate.jpg",
      imageUrl: "../../../../../assets/images/Comingsoon.png",
      // subTitle: "New functionality allows users to upload files directly for processing.  Lear about improved sorting options that simplify navigation.",
      subTitle: "Coming Soon",
      content: "OPOPOPOP"
    })

    this.cards.push({
      title: "Company Management",
      //description: "Learn how to manage store address, define contacts ",
      description: "Coming Soon",
      //imageUrl: "../../../../../assets/images/CoManagement.jpg",
      imageUrl: "../../../../../assets/images/Comingsoon.png",
      // subTitle: "Learn how to manage store address, define contacts allocate communications.",
      subTitle: "Coming Soon",
      content: "OPOPOPOP"
    })

  }
  openDialog() {

    const dialogConfig = new MatDialogConfig();


    // Pass data to the dialog component
      dialogConfig.data = {
      name: 'John',
        age: 30,
        width: '1900px',
        height: '900px',
      };

    const dialogRef = this.dialog.open(ModalComponent, dialogConfig);
    //{
    //  width: '900px',
    //  height: '900px',
    //  disableClose: false,
    //  data: { modalTitle: 'My Dialog Title KING TUT' }

    //});

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed with:', result);
    });
  }


}
