<div class="container responsive-grid">
  <mat-card *ngFor="let card of cards">
    <mat-card-header>
      <mat-card-title>{{ card.title }} </mat-card-title>
      <mat-card-subtitle>{{ card.subTitle }}</mat-card-subtitle>
    </mat-card-header>
    <br />
    <img mat-card-image [src]="card.imageUrl" />
    <mat-card-content>
      <br />
      <p>{{card.description }}</p>
    </mat-card-content>
    <mat-card-actions>
      <!--<button mat-button (click)="openDialog()">Train</button>-->
      <button mat-button>Train</button>
      <button mat-button>Share</button>
    </mat-card-actions>
  </mat-card>
</div>

