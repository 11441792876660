import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { ConfirmationService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { Observable } from "rxjs";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { EzPay, EzPaySavings } from "src/app/models/dashboard/ez-pay";
import { ProUser } from "src/app/models/pro-user";
import { TableColumn, TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";
import { EzPayStatus } from "src/app/modules/controls/enums/ezpayStatus.enum";
import { ThankyouComponent } from "src/app/modules/shopping-cart/components/thankyou/thankyou.component";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SetDashboardHeaderButtons, SetDashboardTitle, ShowDownloadDialog } from "src/app/store/actions/site.actions";
import { selectEzPayStatus } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";
import { PaymentConfirmationComponent } from "./payment-confirmation/payment-confirmation.component";

@Component({
	selector: "app-ez-pay",
	templateUrl: "./ez-pay.component.html",
	styleUrls: ["./ez-pay.component.scss"],
})
export class EzPayComponent implements OnInit {
	public openTab: "Pay" | "Confirmation" | "Reprint" = "Pay";
	public allData: { [key: string]: Array<any> } = {};
	public data: Array<any>;
	public tableInfo: TableInfo;
	public pageSize: number = 999999;
	public pageNumber: number = 1;
	public pageCount: number;
	public ezPayName: "EZ-Pay" | "Prompt Pay" = "EZ-Pay";
	public paymentEnabled: boolean = false;
	public dueDate: Date = new Date();
	public nextEOWDay: Date;
	public totalSelected: number;
	public totalSaved: number;
	public user: ProUser;
	public ezPaySavings: Array<EzPaySavings>;
	private receiptNumber: string = null;
	public searchTerm: string;

	private paySelections: Array<EzPay> = [];
	public enrollStatus: boolean = true;

      ReadOnlyEZPAY: boolean;

      constructor(private store: Store<IAppState>, private dataService: DataService, private authService: AuthService, private route: ActivatedRoute, private router: Router, private confirmService: ConfirmationService, private dialogService: DialogService) { }

        ngOnInit(): void {

        this.ReadOnlyEZPAY = true;

		    const nowEst = new Date(this.getEstDateTime());

          switch (nowEst.getDay()) {
			case 0:
			case 6:
				//disabled on weekends
				this.paymentEnabled = false; break;
			case 1: 
				//monday's after 9am is enabled
				this.paymentEnabled = nowEst.getHours() > 8; break;
			case 5:
				//fridays only enabled till 2pm
				this.paymentEnabled = nowEst.getHours() < 14; break;
			default:
				//all other days ok
            this.paymentEnabled = true; break;

		}
        const today = new Date();

        this.dueDate = new Date(today.getFullYear(), today.getMonth(), 10, 0, 0, 0);

		if (this.dueDate < new Date()) {
			this.dueDate.setMonth(this.dueDate.getMonth() + 1);
		}

		this.user = this.authService.user;

		switch (this.user.userType) {
			case "Member":
				this.nextEOWDay = this.getNextDayOfWeek(5);
				break;
			case "Client":
				this.nextEOWDay = this.getNextDayOfWeek(5);
				break;
		}
		this.store.pipe(select(selectEzPayStatus)).subscribe((s) => {
			switch (s) {
				case EzPayStatus.EzPay:
					this.ezPayName = "EZ-Pay";
					break;
				case EzPayStatus.PromptPay:
					this.ezPayName = "Prompt Pay";
					break;
				case EzPayStatus.NoAccess:
					this.router.navigate(["/no-access"]);
			}
		});
		this.store.dispatch(new SetDashboardTitle(this.ezPayName));
		let buttons: Array<DashboardHeaderButton> = [
			//new DashboardHeaderButton({ text: "Search", cssClass: "search-button", icon: "search", callback: () => {} }),
			new DashboardHeaderButton({ text: "Download", icon: "download", callback: () => this.showDownloadOptions() }),
		];
		this.store.dispatch(new SetDashboardHeaderButtons(buttons));

          this.dataService.getEzpaySavings().subscribe((s) => (this.ezPaySavings = s));

		this.dataService.getEzPayEnrollmentStatus().subscribe((resp:any) => this.enrollStatus = (resp?.status == 1))

		this.route.paramMap.subscribe((param) => {
			if (param.get("term")) {
				this.searchTerm = param.get("term");
				this.tableInfo = this.allTableInfo[this.openTab];
				this.search();
			} else {
				this.loadTabData();
			}
		});
	}

	getNextDayOfWeek(dayOfWeek) {
		const today = new Date();
		console.warn("need to check if after 3pm ET");
		//today.setHours(15, 0, 0);
		//today = this.changeTimeZone(today, "America/New_York");
		const fri = today.getDate() + ((dayOfWeek + 7 - today.getDay()) % 7);
		return new Date(today.setDate(fri));
	}
	changeTimeZone(date, ianatz) {
		var invdate = new Date(
			date.toLocaleString("en-US", {
				timeZone: ianatz,
			})
		);

		var diff = date.getTime() - invdate.getTime();

		return new Date(date.getTime() + diff);
	}
	loadTabData() {
		if (!this.allData[this.openTab] || this.openTab == "Confirmation") {
			//always load confirmation, since that could have different receipt number
			let apiCall: Observable<any>;
			let tab: string;
			switch (this.openTab) {
				case "Pay":
					apiCall = this.dataService.getEzpay();
					tab = "Pay";
					break;
				case "Confirmation":
					apiCall = this.dataService.getEzpayReceipt(this.receiptNumber);
					tab = "Confirmation";
					break;
				case "Reprint":
					apiCall = this.dataService.getEzpayHistorical();
					tab = "Reprint";
					break;
			}
			apiCall.subscribe((r) => {
				if(tab == 'Pay') {
					this.allData[tab] = r.map(i => new EzPay(i));
				} else {
					this.allData[tab] = r;
				}
				this.setData();
			});
		} else {
			this.setData();
		}
		this.tableInfo = this.allTableInfo[this.openTab];
	}

	clearSearch() {
		this.searchTerm = "";
		this.allData[this.openTab] = null;
		this.loadTabData();
	}
  setData() {
   
		let first = (this.pageNumber - 1) * this.pageSize;
		let last = first + this.pageSize;
    this.data = this.allData[this.openTab].slice(first, last);
		this.pageCount = Math.ceil(this.allData[this.openTab].length / this.pageSize);

  }

	setTab(tab) {
		this.pageNumber = 1;
		this.openTab = tab;
		this.loadTabData();
	}

	showDownloadOptions() {
		this.store.dispatch(new ShowDownloadDialog(this.allData[this.openTab], this.allTableInfo[this.openTab], []));
	}

	goToPage(page) {
		this.pageNumber = page;
		this.setData();
	}

  checkboxChecked(data, isChecked, onDue10th = false) {

    if (this.allData[this.openTab].find((i) => i.invoice == data.invoice).paidDate  !== null)
    {
   
      alert("This invoice has already been paid on: " + this.allData[this.openTab].find((i) => i.invoice == data.invoice).paidDate.replace('T00:00:00','')  )

      this.clearSearch()
      return;

    }
  
		switch (this.openTab) {
			case "Pay":
				data.isChecked = isChecked;
        this.allData[this.openTab].find((i) => i.invoice == data.invoice).isChecked = isChecked;
        this.paySelections = this.paySelections.filter((p) => p.invoice != data.invoice); //removing, then if we have to add back in do so.  prevents adding same one twice

        if (onDue10th) {
					data.deductionDateDisplay = isChecked ? this.dueDate : data.deductionDate;
				}
				if (isChecked) {
					this.paySelections.push(data);
				} else {
					data.deductionDateDisplay = data.deductionDate; //reset deduction date if not sending this one
				}
				this.totalSelected = this.paySelections.reduce((a, p) => a + p.totalToPay, 0);
				this.totalSaved = this.paySelections.reduce((a, p) => a + p.ezPayDiscount, 0);
		}
	}

	payAll() {
		if(!this.paymentEnabled) return;
		let total = this.allData[this.openTab].reduce((a,i) => a + i.totalToPay, 0)
		if (total <= 0) {
			this.store.dispatch(new ErrorMessageSet("Total Dollar amount submitted must be positive"));
			return;
		}
		this.confirmService.confirm({
			message: `Are you sure you want to pay all open invoices?`,
			accept: () => {
				this.allData[this.openTab].forEach((i) => this.checkboxChecked(i, true));
				this.doPay();
			},
		});
	}
	paySelected() {
		if(!this.paymentEnabled) return;
		if (this.totalSelected <= 0) {
			this.store.dispatch(new ErrorMessageSet("Total Dollar amount submitted must be positive"));
			return;
		}
		this.confirmService.confirm({
			message: `Are you sure you want to pay all selected invoices?`,
			accept: () => {
				this.doPay();
			},
		});
	}
	doPay() {
		this.dataService.payEzPay(this.paySelections.map((i) => ({ Invoice: i.invoice, DeductionDate: i.deductionDateDisplay }))).subscribe((resp) => {
			this.allData["Pay"] = null; //clear this out so it gets fresh when you return to this tab
			this.openTab = "Confirmation";
			this.receiptNumber = null;
			this.loadTabData();
			this.dialogService.open(PaymentConfirmationComponent, {
				data: {
					receiptNumber: resp,
				},
			});
		});
	}
  search() {
		this.openTab = "Pay";
		this.dataService.searchEzPay(this.searchTerm).subscribe((d: any) => {
    this.allData[this.openTab] = d;
		this.setData();
		});
	}
	reset() {
		if(!this.paymentEnabled) return;
		this.allData[this.openTab].forEach((i) => this.checkboxChecked(i, false));
	}
	selectDue10th() {
		this.allData[this.openTab].forEach((i) => this.checkboxChecked(i, new Date(i.due_date).getTime() == this.dueDate.getTime(), true));
	}
	selectFridayEzPay() {
		this.allData[this.openTab].forEach((i) => this.checkboxChecked(i, i.showEligible || new Date(i.date) == this.nextEOWDay));
	}
	selectLate() {
		this.allData[this.openTab].forEach((i) => this.checkboxChecked(i, new Date(i.due_date).getTime() < new Date().getTime()));
	}
	reprintInvoice(row, caller) {
		const Receipt = row.receiptNumber;
		caller.dataService.reprintInvoices(Receipt).subscribe(
			(resp: any) => {
				const a = document.createElement("a");
				a.setAttribute("style", "display:none;");
				document.body.appendChild(a);
				a.download = "Billings" + ".pdf";
				a.href = URL.createObjectURL(resp);
				a.target = "_blank";
				a.click();
				document.body.removeChild(a);
			},
			(err) => {
				switch (err.error.size) {
					case 30: // can't find file
						caller.store.dispatch(new ErrorMessageSet("The receipt was not found"));
						break;
					case 32: //not your invoice
						caller.store.dispatch(new ErrorMessageSet("The receipt number was not valid"));
						break;
					default:
						caller.store.dispatch(new ErrorMessageSet("There was a problem generating your receipt"));
				}
			}
		);
	}
	reprintConfirmation(row, caller) {
		caller.dialogService.open(PaymentConfirmationComponent, {
			data: {
				receiptNumber: row.receiptNumber,
				date: row.date,
				DateSubmitted: row.dateSubmitted,
			},
		});
	}

	ConsolidateEZPAY() {
		this.dataService.downloadEZPAY().subscribe(
			(resp: any) => {
				const a = document.createElement("a");
				a.setAttribute("style", "display:none;");
				document.body.appendChild(a);
				a.download = "Billings" + ".pdf";
				a.href = URL.createObjectURL(resp);
				a.target = "_blank";
				a.click();
				document.body.removeChild(a);
			},
			(err) => {
				switch (err.error.size) {
					case 30: // can't find file
						this.store.dispatch(new ErrorMessageSet("The invoice was not found"));
						break;
					case 32: //not your invoice
						this.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
						break;
				}
			}
		);
	}

	downloadInvoice(row, caller) {
		//const id = row.link.replace('https://members.promaster.com/Invoices/', '').replace('.pdf', '')
		const id = row.invoice;
		caller.dataService.downloadInvoice(id).subscribe(
			(resp: any) => {
				const a = document.createElement("a");
				a.setAttribute("style", "display:none;");
				document.body.appendChild(a);
				a.download = id + ".pdf";
				a.href = URL.createObjectURL(resp);
				a.target = "_blank";
				a.click();
				document.body.removeChild(a);
			},
			(err) => {
				switch (err.error.size) {
					case 30: // can't find file
						caller.store.dispatch(new ErrorMessageSet("The invoice was not found"));
						break;
					case 32: //not your invoice
						caller.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
						break;
				}
			}
		);
	}

	setEnrollStatus() {
		this.dataService.saveEzPayEnrollmentStatus(this.enrollStatus ? 1 : 0).subscribe();
	}

	getEstDateTime() {
		//returns current date/time in EST
		return new Date().toLocaleString("en-US", {timeZone: 'America/New_York'});
	}
	get allTableInfo() {
		let vals = {
			Pay: new TableInfo({
				paging: { pageSize: 20 },
				columns: [
          { title: "", field: "isChecked", type: "checkbox",   cssClass: "centered" },
          { title: "PRO Invoice #", field: "invoice",  type: "action", callback: this.downloadInvoice, id: true, sort: true },
					{ title: `1% ${this.ezPayName} Eligible`, field: "showEligible", type: "checkmark", hideAtBp: 1 },
					{ title: "Due Date", field: "due_date", type: "date", format: "MM/dd/yyyy", sort: true },
          { title: "Posted Date", field: "date", type: "date", format: "MM/dd/yyyy", sort: true, hideAtBp: 1 },
          { title: "Deduction Date", field: "deductionDateDisplay", type: "date", format: "MM/dd/yyyy", sort: true },
					{ title: "Vendor", field: "vendor", sort: true, hideAtBp: 1 },
					{ title: "Vendor Invoice Number", field: "vendorInvoiceNumber", sort: true, hideAtBp: 1 },
					{ title: "Gross", field: "gross", type: "currency", sort: true, hideAtBp: 1, total: true },
					{ title: "Pro Disc", field: "proDiscount", type: "currency", hideAtBp: 1, total: true, sort: true },
					{ title: `${this.ezPayName} Discount`, field: "ezPayDiscount", type: "currency", hideAtBp: 1, total: true, sort: true },
					{ title: "Total To Pay", field: "totalToPay", type: "currency", total: true },
				],
			}),
			Confirmation: new TableInfo({
				columns: [
					{ title: "PRO Invoice #", field: "invoice", type: "action", callback: this.downloadInvoice, id: true, sort: true },
					{ title: `1% ${this.ezPayName} Eligible`, field: "showEligible", type: "checkmark", hideAtBp: 1 },
					{ title: "Due Date", field: "due_date", type: "date", format: "MM/dd/yyyy", sort: true, hideAtBp: 3 },
          { title: "Posted Date", field: "date", type: "date", format: "MM/dd/yyyy", sort: true, hideAtBp: 3 },
          { title: "Deduction Date", field: "deductionDate", type: "date", format: "MM/dd/yyyy", sort: true },
					{ title: "Vendor", field: "vendor", sort: true, hideAtBp: 1 },
					{ title: "Receipt Number", field: "receiptNumber", width: "2fr", sort: true, hideAtBp: 3 },
					{ title: "Gross", field: "gross", type: "currency", sort: true, total: true, hideAtBp: 1 },
					{ title: "Pro Disc", field: "proDiscount", type: "currency", total: true, sort: true },
					{ title: `${this.ezPayName} Discount`, field: "ezPayDiscount", type: "currency", total: true, sort: true },
					{ title: "Total To Pay", field: "totalToPay", type: "currency", total: true },
				],
			}),
			Reprint: new TableInfo({
				columns: [
          { title: "Date", field: "date", type: "date", format: "MM/dd/yyyy" },
					{ title: "Receipt Number", field: "receiptNumber", sort: true },
					{ title: "Total", field: "gross", type: "currency", sort: true },
					{ title: "Reprint Invoices", text: "Reprint Invoices", type: "action", callback: this.reprintInvoice },
					{ title: "Reprint Confirmation", text: "Reprint Confirmation", type: "action", callback: this.reprintConfirmation },
				],
				service: this.dataService,
				dataMethod: "getEzpayHistoricalDetail",
				hasSubtable: true,
				rowKey: "receiptNumber",
				subtableInfo: new TableInfo({
					columns: [
						{ title: "Invoice", field: "invoice", type: "action", callback: this.downloadInvoice, sort: true },
						{ title: "Vendor", field: "vendor", sort: true },
						{ title: "Posted", field: "date", type: "date", format: "MM/dd/yyyy", sort: true },
						{ title: "Vendor Invoice", field: "vendorInvoiceNumber", sort: true },
						{ title: "Gross", field: "gross", type: "currency", sort: true },
						{ title: "PRO Discount", field: "proDiscount", type: "currency", sort: true },
						{ title: `${this.ezPayName} Discount`, field: "ezPayDiscount", type: "currency", sort: true },
						{ title: "Net", field: "totalToPay", type: "currency", sort: true },
						{ title: "Due On", field: "checkTerms", sort: true },
					],
				}),
			}),
		};
		if (this.ezPayName == "Prompt Pay") {
			vals.Pay = new TableInfo({
				columns: [
					{ title: "PRO Invoice #", field: "invoice", type: "link", link: "link", id: true, sort: true },
					{ title: "Due Date", field: "date", type: "date", format: "MM/dd/yyyy", sort: true },
					{ title: "Posted Date", field: "date", type: "date", format: "MM/dd/yyyy", sort: true },
					{ title: "Deduction Date", field: "deductionDate", type: "date", format: "MM/dd/yyyy", sort: true },
					{ title: "Vendor", field: "vendor", sort: true },
					{ title: "Vendor Invoice Number", field: "vendorInvoiceNumber", sort: true },
					{ title: "Gross", field: "gross", type: "currency", sort: true },
					{ title: "Pro Disc", field: "proDiscount", type: "currency" },
					{ title: "Total To Pay", field: "totalToPay", type: "currency" },
				],
			});
    }
    console.log(vals);
		return vals;
	}
}
