import { Product } from "../shopping/product";

export class WarehouseSearchResults {
	public products: Array<Product>;
	public totalResults: number;
	public page: number;
	public pages: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class WarehouseSearchCriteria {
	public keyword: string;
	public brandIds: Array<number>;
	public categoryIds: Array<number>;
	public pageNumber: number;
	public pageSize: number;
	public sort: string;
	public sortDir: string;
  public newProductsOnly: boolean;
  public discontinued: boolean;
	public memoryOnly: boolean;
	public rebates: boolean;
	public multiples: boolean;
	public specials: boolean;
	public vendorDirectShip: boolean;
	public topSellers: boolean;
	public minPrice: number;
  public maxPrice: number;
  public inStockOnly: boolean;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}



