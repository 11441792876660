import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingLibraryComponent } from './components/training-library/training-library.component';
import { CardModule } from 'primeng/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalComponent } from './components/modal/modal.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations:
    [
    TrainingLibraryComponent,
    ModalComponent,
    ],
  imports:
   [
   CommonModule,
   CardModule,
   MatButtonModule,
   MatCardModule,
      BrowserAnimationsModule,
      MatDialogModule
  ],
  providers: []
})
export class TrainingModule { }
