import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingHomeComponent } from './components/shopping-home/shopping-home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartTileComponent } from './components/cart-tile/cart-tile.component';
import { CategoryPageComponent } from './components/category-page/category-page.component';
import { RouterModule } from '@angular/router';
import { ShoppingTemplateComponent } from './components/shopping-template/shopping-template.component';
import { ProductPageComponent } from './components/product-page/product-page.component';
import { CartPageComponent } from './components/cart-page/cart-page.component';
import { ProductImagesComponent } from './components/product-page/product-images/product-images.component';
import { BrandPageComponent } from './components/brand-page/brand-page.component';
import { ProductDetailsComponent } from './components/product-page/product-details/product-details.component';
import { TechSpecsComponent } from './components/product-page/tech-specs/tech-specs.component';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { CartTileItemComponent } from './components/cart-tile/cart-tile-item/cart-tile-item.component';
import { QuickAddComponent } from './components/quick-add/quick-add.component';
import { DropdownModule } from 'primeng/dropdown';
import { SpecialsComponent } from './components/specials/specials.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { SpecialDetailsComponent } from './components/specials/special-details/special-details.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MatIconModule } from '@angular/material/icon';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ControlsModule } from '../controls/controls.module';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { BundlePageComponent } from './components/bundle-page/bundle-page.component';
import { BundleImagesComponent } from './components/bundle-page/bundle-images/bundle-images.component';
import { KeyFilterModule } from 'primeng/keyfilter';

@NgModule({
  declarations: [ShoppingHomeComponent, CartTileComponent, CategoryPageComponent, ShoppingTemplateComponent, ProductPageComponent, CartPageComponent, ProductImagesComponent, BrandPageComponent, ProductDetailsComponent, TechSpecsComponent, CartTileItemComponent,  QuickAddComponent, SpecialsComponent, SpecialDetailsComponent, ThankyouComponent, ReceiptComponent, BundlePageComponent, BundleImagesComponent],
  imports: [
  CommonModule,
	FormsModule,
	RouterModule,	
	ReactiveFormsModule,
  KeyFilterModule,
	ButtonModule,
	FileUploadModule,
	DropdownModule,
	InputNumberModule,
	DynamicDialogModule,
	MatIconModule,
	InputTextModule,
	DialogModule,
	ControlsModule,
	OverlayPanelModule,
	DynamicDialogModule
  ],
  exports: [
	  ShoppingHomeComponent,
	  CartTileComponent,
  ],
  providers: [DialogService]
})
export class ShoppingCartModule { }
