import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { RebateEntry, RebateFile, RebateForm, RebateLineItem } from "src/app/models/dashboard/rebates";
import { ProUser } from "src/app/models/pro-user";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";
import { Table } from "primeng/table";

@Component({
	selector: "app-rebate-claim-form",
	templateUrl: "./rebate-claim-form.component.html",
	styleUrls: ["./rebate-claim-form.component.scss"],
})
export class RebateClaimFormComponent implements OnInit {
	@Input() rebate: RebateEntry;

	@Output() onSubmit: EventEmitter<RebateEntry> = new EventEmitter();

	public step: number = 1;

	public rebateForm: UntypedFormGroup;
	private user: ProUser;
	public item: RebateLineItem[] = [];
	public RequiresAdditionalUpload: Boolean = false;
	public programDirections: RebateLineItem[] = [];
  public columns: any[] = [];
  public countPrograms: number;
	constructor(private dataService: DataService, private fb: UntypedFormBuilder, private store: Store<IAppState>, private datePipe: DatePipe) {}

	ngOnInit(): void {
		this.store.pipe(select(selectUser)).subscribe((u) => (this.user = u));

		if (!this.rebate.rebateForm) {
			this.rebate.rebateForm = new RebateForm();
		}

		this.columns = [
			{ header: "Rebate Type", field: "rebateType" },
			{ header: "Pro Code", field: "proCode" },
			{ header: "Model", field: "modelName" },
			{ header: "Reimbursement", field: "reimbursement" },
			{ header: "Quantity", field: "quantity" },
		];

		if (!this.rebate.rebateLineItems) {
			this.dataService.getRebateProgramProducts(this.rebate.rebateProgram.vendorId, this.rebate.rebateProgram.startDate, this.rebate.rebateProgram.endDate).subscribe((r: any) => {
				this.rebate.rebateLineItems = r.map((i) => new RebateLineItem(i));
				this.item = r.map((i) => new RebateLineItem(i));
			});
		}

		this.rebateForm = this.fb.group({
			fname: [this.rebate.rebateForm.fname || this.user.name.split(" ")[0], Validators.required],
			lname: [this.rebate.rebateForm.lname || this.user.name.split(" ")[1], Validators.required],
			email: [this.rebate.rebateForm.email || this.user.email, Validators.required],
			phone: [this.rebate.rebateForm.phone, Validators.required],
			memo: [this.rebate.rebateForm.memo, [Validators.required, Validators.minLength(5)]],
		});
	}

	validateRebate(item: RebateLineItem) {
		this.dataService.validateRebate(item.modelID, item.quantity).subscribe((resp) => {
			item.available = Math.max(0, resp.available);
			item.status = resp.status;

			//item.available = Math.max(0, resp.available);
			//item.status = resp.status;
		});
	}

	get total() {
		this.rebate.rebateLineItems = this.item;
		return this.rebate.rebateLineItems.filter((i) => i.quantity > 0).reduce((a, b) => a + b.quantity * b.reimbursement, 0);
	}
	back() {
		if (this.step > 1) {
			this.step--;
		}
	}
	next() {
		if (this.step < 3) {
			//Just standard upload, no secondary required.
			if (this.step == 2 && !this.rebate.rebateFiles?.length && !this.RequiresAdditionalUpload) {
				this.store.dispatch(new ErrorMessageSet("Please attach at least one supporting document for POS"));
				return;
			}
			//Just both upload,  secondary required.
			if (this.step == 2 && !this.rebate.rebateFiles?.length && !this.rebate.rebateFilesSecondary?.length && this.RequiresAdditionalUpload) {
				this.store.dispatch(new ErrorMessageSet("Please attach at least one supporting document for POS AND additional proof of performance, such as a screenshot of a social media post "));
				return;
			}
			//only secondary missing.
			if (this.step == 2 && this.rebate.rebateFiles?.length && !this.rebate.rebateFilesSecondary?.length && this.RequiresAdditionalUpload) {
				this.store.dispatch(new ErrorMessageSet("Please attach additional proof of performance, such as a screenshot of a social media post "));
				return;
			}
			//only Primary missing.
			if (this.step == 2 && this.rebate.rebateFilesSecondary?.length && !this.rebate.rebateFiles?.length && this.RequiresAdditionalUpload) {
				this.store.dispatch(new ErrorMessageSet("Please attach at least one supporting document for POS"));
				return;
			}

			//Need to audit type chosen
			if (this.step == 1) {
				const selectedItems = this.item.filter((i) => i.quantity > 0);

				if (selectedItems.length == 0) {
					this.store.dispatch(new ErrorMessageSet("At minimum, you must select one model for this program."));
					return;
				}

        selectedItems.forEach((x: any) =>
        {
					const index = this.programDirections.filter((item) => item.rebateTypeID === x.rebateTypeID);
          if (index.length > 0)
          {
          }
          else
          {
						if (x.rebateDirections != null) this.programDirections.push(x);
						if (x.rebateTypeID === 6) this.RequiresAdditionalUpload = true;
					}
        });

        this.countPrograms = this.programDirections.length;

			}
			this.step++;
		}
	}

	saveFileAdditional(elem) {
		if (!this.rebate.rebateFilesSecondary) {
			this.rebate.rebateFilesSecondary = [];
		}

		const file = elem.target.files[0];
		this.rebate.rebateFilesSecondary.push(new RebateFile({ file: file, fileName: file.name, fileSize: file.size }));
		elem.target.value = null;
	}

	saveFile(elem) {
		if (!this.rebate.rebateFiles) {
			this.rebate.rebateFiles = [];
		}

		const file = elem.target.files[0];
		this.rebate.rebateFiles.push(new RebateFile({ file: file, fileName: file.name, fileSize: file.size }));
		elem.target.value = null;
	}

	deleteFileFileAdditional(i) {
		this.rebate.rebateFilesSecondary.splice(i, 1);
	}

	deleteFile(i) {
		this.rebate.rebateFiles.splice(i, 1);
	}

	onGlobalFilter(table: Table, event: Event) {
		table.filterGlobal((event.target as HTMLInputElement).value, "contains");
	}

	submit() {
		this.rebateForm.markAllAsTouched();
		if (this.rebateForm.valid) {


      this.rebate.rebateForm = new RebateForm(this.rebateForm.value);

		  this.rebate.rebateLineItems = this.item;

			this.rebate.rebateLineItems = this.rebate.rebateLineItems.filter((i) => i.quantity > 0).map((i) => new RebateLineItem({ modelID: i.modelID, programDate: `${this.datePipe.transform(this.rebate.rebateProgram.startDate, "MMM dd")} - ${this.datePipe.transform(this.rebate.rebateProgram.endDate, "MMM dd, yyyy")}`, reimbursement: i.reimbursement, quantity: i.quantity, serial: i.serial, modelName: i.model }));

			//save files to send later
			const file = [...this.rebate.rebateFiles];
			

      const files = file;

      if (this.RequiresAdditionalUpload) {
        const filesSecondary = [...this.rebate.rebateFilesSecondary];
				// Loop through rebateFilesSecondary
				this.rebate.rebateFilesSecondary.forEach((item) => {
					this.rebate.rebateFiles.push(item);
        });

        const files = file.concat(filesSecondary);
			}



			this.rebate.rebateFiles = this.rebate.rebateFiles.map((f) => new RebateFile({ fileName: f.fileName, fileSize: f.fileSize }));

			const { rebateProgram, ...entry } = this.rebate;

			this.dataService.submitRebate(entry).subscribe((resp: any) => {
				this.rebate.claimId = resp.data.claimId;
				this.dataService
					.submitRebateFiles(
						resp.data.claimId,
						files.map((f) => f.file)
					)
					.subscribe((resp) => {
						this.store.dispatch(new SuccessMessageSet("Your rebate has been successfully submitted"));

						this.onSubmit.next(this.rebate);
					});
			});
		}
	}

	uploadQuantities(e) {
		console.log(e.srcElement.files[0]);
		this.dataService.uploadRebateQuantityFile(this.rebate.rebateProgram.vendorId, this.rebate.rebateProgram.startDate, this.rebate.rebateProgram.endDate, e.srcElement.files[0]).subscribe((resp) => {
			this.item = resp.map((i) => new RebateLineItem(i));
			this.item.forEach((i) => this.validateRebate(i));
		});
	}
}
