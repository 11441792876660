import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { DataService } from "src/app/services/data.service";
import { AddToCart, AddToCartSuccess, EditCartItem, GetCartSpecialsSuccess, GetCartSuccess } from "src/app/store/actions/cart.actions";
import { IAppState } from "src/app/store/state/app.state";
import { takeUntil } from 'rxjs/operators';
import { CloseQuickAdd, ErrorMessageSet, OpenQuickAdd, ShowUploadOrder } from "src/app/store/actions/site.actions";
import { CartItem, CartItemUpsell } from "src/app/models/shopping/cart";
import { AppliedSpecial } from "src/app/models/shopping/specials";

@Component({
	selector: "app-quick-add-widget",
	templateUrl: "./quick-add-widget.component.html",
	styleUrls: ["./quick-add-widget.component.scss"],
})

export class QuickAddWidgetComponent extends AutoUnsubscribeMixin() implements OnInit {
	@ViewChild('productCodeField') productCodeField: ElementRef
	@ViewChild('quantityField') quantityField: ElementRef
	public productCode: string;
	public quantity: number;
	public modelName: string;
	private multiple: number;

    public returnCode: string;
    public originalProductCode: string;
    public originalProductName: string;

    public added: Array<AddedItem> = [];

	private isVisible: boolean = false;
	constructor(private store: Store<IAppState>, private actions$: Actions, private dataService: DataService) {super();}

	ngOnInit(): void {
		this.actions$.pipe(takeUntil(this.destroy$)).subscribe(a => {
			if(a instanceof OpenQuickAdd) {
				this.isVisible = true;
			}
            if (a instanceof CloseQuickAdd) {

				this.isVisible = false;
				this.added = [];
			}
			if(a instanceof AddToCartSuccess) {
                if (this.isVisible) {
					let existing = this.added.find(i => i.productCode == a.result.data.productCode);
                if (existing)
                {
						existing.quantity += a.result.data.quantity;
                }
                else
                {          
                  var cartitem = new AddedItem(a.result.data);
                  cartitem.originalProductCode = this.originalProductCode;
                  cartitem.originalProductName = this.originalProductName;
                  cartitem.returnCode = this.returnCode;
                  this.added.push(cartitem);              
				}					
				}
      }

            if (a instanceof GetCartSuccess) {
            }

			if(a instanceof GetCartSuccess) {
				if(this.isVisible){
                this.added.forEach(i => {

						let cartItem = a.cart.cartItems.find(ci => ci.cartItemId == i.cartItem.cartItemId);
						i.itemUpsell = cartItem.itemUpsell;
                        i.rejectUpsell = cartItem.rejectUpsell;
					})					
				}
			}
			if(a instanceof GetCartSpecialsSuccess) {
				if(this.isVisible) {
					a.appliedSpecials.forEach(s => {
						let item = this.added.find(i => i.cartItem.cartItemId == s.cartItemId);
						if(item) {
							item.appliedSpecial = s;
						}
					})					
				}
			}
		})
	}
  addItem() {

    this.dataService.getProduct(this.productCode).subscribe((p: any) =>
    { 

    if (p.product)
    {
      if (!p.product.isDiscontinued)
      {
        if (this.quantity % this.multiple > 0)
        {
			    this.store.dispatch(new ErrorMessageSet(`Product ${this.productCode} is only available in multiples of ${this.multiple}`))
			    this.quantity = undefined;
			    this.quantityField.nativeElement.focus();
        }
        else
        {
			    this.store.dispatch(new AddToCart(this.productCode, this.quantity));
			    this.productCode = '';
			    this.quantity = null;
          this.productCodeField.nativeElement.focus();
        }
      }
    }
    })
  }


  getProduct() {

    if (this.productCode)
    {
       this.dataService.getProduct(this.productCode).subscribe((p: any)=> {
         if (p.product)
         {
           if (p.product.isDiscontinued)
           {             
             this.store.dispatch(new ErrorMessageSet("This product is discontinued"));
             this.productCode = "";
             this.productCodeField.nativeElement.focus();
           }
           else 
           {
             this.modelName = p.product.modelName;
             this.multiple = p.product.multiple;
             this.originalProductCode = p.data.originalProductCode;
             this.returnCode = p.returnCode;
             this.originalProductName = p.data.originalProductName;
           }
 
				}
       },
         (err) => {   
          this.store.dispatch(new ErrorMessageSet("Invalid Product Code"));
				  this.productCode = "";

          this.productCodeField.nativeElement.focus();

       
       }) 
		}
	}

	delete(item: AddedItem) {
		this.store.dispatch(new EditCartItem(item.cartItem.cartItemId, item.productCode, 0, false));
		this.added = this.added.filter(i => i.productCode != item.productCode)
	}
	uploadOrder() {
		this.store.dispatch(new ShowUploadOrder());
	}

	acceptUpsell(cartItemId, productCode, itemUpsell) {
		this.store.dispatch(new EditCartItem(cartItemId, productCode, itemUpsell.upsellQuantity, true));
	}
	rejectUpsell(cartItemId, productCode, itemUpsell) {
		this.store.dispatch(new EditCartItem(cartItemId, productCode, itemUpsell.noUpsellQuantity, true));
	}
	goToQty() {
		this.quantityField.nativeElement.focus();
	}

}

class AddedItem {
	public productCode: string;
	public modelName: string;
	public retail: number;
	public quantity: number;
	public cartItem: CartItem;
	public itemUpsell: CartItemUpsell;
	public rejectUpsell: boolean = false;
	public appliedSpecial: AppliedSpecial;

    public returnCode: string;
    public originalProductCode: string;
    public originalProductName: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}
