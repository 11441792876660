import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing-module';
import { ShoppingCartModule } from './modules/shopping-cart/shopping-cart.module';
import { DataService } from './services/data.service';
import { SiteService } from './services/site.service';
import { EffectsModule } from '@ngrx/effects';
import { CartEffects } from './store/effects/cart.effects';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './store/reducers/app.reducer';
import { CartService } from './services/cart.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabViewModule } from 'primeng/tabview';
import { RetailerModule } from './modules/retailer/retailer.module';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AuthModule } from './modules/auth/auth.module';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TemplateComponent } from './components/template/template.component';
import { SearchComponent } from './components/search/search.component';
import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';
import { UserEffects } from './store/effects/user.effects';
import { SearchWidgetComponent } from './components/template/search-widget/search-widget.component';
import { StrapiService } from './services/strapi.service';
import { LandingComponent } from './components/landing/landing.component';
import { MockStrapiService } from './services/mock-strapi.service';
import { AuthGuard } from './guards/auth.guard';
import { CmsModule } from './modules/cms/cms.module';
import { ControlsModule } from './modules/controls/controls.module';
import { WarehouseModule } from './modules/warehouse/warehouse.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { StaffComponent } from './components/staff/staff.component';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RootComponent } from './components/root/root.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CarouselModule } from 'primeng/carousel';
import { CalendarComponent } from './components/calendar/calendar.component';
import { DropdownModule } from 'primeng/dropdown';
import { QuickAddWidgetComponent } from './components/template/quick-add-widget/quick-add-widget.component';
import { MatIconModule } from '@angular/material/icon'
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { DealersComponent } from './components/dealers/dealers.component';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { PermissionGuard } from './guards/permission.guard';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SearchResultPipe } from './pipes/search-result.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { AlgoliaService } from './services/algolia.service';
import { InputMaskModule } from 'primeng/inputmask';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChipModule } from 'primeng/chip';
import { TrainingModule } from './modules/training/training.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    TemplateComponent,
    SearchComponent,
    SearchWidgetComponent,
    LandingComponent,
    StaffComponent,
    SearchResultPipe,
    SafePipe,
    RootComponent,
    CalendarComponent,
    QuickAddWidgetComponent,
    DealersComponent,
    NoAccessComponent
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ShoppingCartModule,
    RetailerModule,
    WarehouseModule,
    TrainingModule,
    DashboardModule,
    CmsModule,
    AuthModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([CartEffects, UserEffects]),
    MatIconModule,
    ControlsModule,
    TableModule,
    TabViewModule,
    BadgeModule,
    SidebarModule,
    ButtonModule,
    ChipModule,
    RadioButtonModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    SidebarModule,
    CarouselModule,
    DialogModule,
    ConfirmDialogModule,
    DragDropModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    TooltipModule,
    InputMaskModule,
    KeyFilterModule,
    PdfViewerModule,
    MatButtonModule, MatCardModule,
    FlexLayoutModule, MatToolbarModule, MatDialogModule

  ],
  providers:
    [ DataService,
      SiteService, CartService, AlgoliaService,
      MockStrapiService, StrapiService,
      MessageService, AuthGuard, PermissionGuard,
      ConfirmationService,   
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, 
      { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }],
      bootstrap: [AppComponent]
})
export class AppModule { }


