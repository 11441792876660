<div class="table" [ngStyle]="{'grid-template-columns': columnWidths}">
	<div class="header" *ngIf="tableInfo.hasSubtable">&nbsp;</div>
	<ng-container *ngFor="let col of columns">
		<div class="header {{col.hideAtBp != undefined ? 'hide-at-bp' + col.hideAtBp : ''}}" *ngIf="col.type !== 'hidden'" (click)="sort(col)" [ngClass]="{sortable: col.sort}">{{col.title}}</div>
	</ng-container>
	<ng-container *ngFor="let row of pageData; let rowIndex = index">
		<div *ngIf="tableInfo.hasSubtable" (click)="expand(row)" class="expander">+</div>
		<ng-container *ngFor="let col of columns; let colIndex = index">
			<div class="value {{col.cssClass}} {{col.hideAtBp != undefined ? 'hide-at-bp' + col.hideAtBp : ''}}" *ngIf="col.type !== 'hidden'" [ngClass]="{odd: rowIndex % 2 == 1}">
				<ng-container *ngIf="(!col.showIf || row[col.showIf.field]) && col.type !== 'hidden'">
					<ng-container [ngSwitch]="col.type">
						<span *ngSwitchCase="'checkbox'">

						<p-checkbox name="checkbox_{{row[idColName]}}" (onChange)="checkboxChecked($event, row)" [(ngModel)]="row[col.field]"   binary="true"></p-checkbox></span>

						<span *ngSwitchCase="'date'">{{row[col.field] | date: col.format}}</span>
						<span *ngSwitchCase="'currency'">{{row[col.field] | currency }}</span>
						<span *ngSwitchCase="'checkmark'"><i class="checkmark" *ngIf="row[col.field]"></i><i class="x-mark" *ngIf="!row[col.field]"></i></span>
						<span *ngSwitchCase="'action'" (click)="doCallback(col.callback, row)" class="action">{{col.text || row[col.field]}}</span>
						<span *ngSwitchCase="'status'" [ngClass]="(row[col.field] || '').toLowerCase()">{{row[col.field]}}</span>
						<span *ngSwitchCase="'link'"><a href="{{row[col.link]}}" target="_blank" *ngIf="row[col.link]">{{col.text ? col.text : row[col.field]}}</a></span>
						<span *ngSwitchCase="'phone'">{{row[col.field] | phone }}</span>
						<span *ngSwitchDefault [innerHtml]="row[col.field]"></span>
					</ng-container>
				</ng-container>
			</div>
		</ng-container>
		<div *ngIf="tableInfo.hasSubtable && expandedRow == row[tableInfo.rowKey]" class="subtable" [ngStyle]="{'grid-column': '1 / span ' + columnCount}">
			<app-table [data]="row.subtableData" [tableInfo]="tableInfo.subtableInfo" [caller]="caller"></app-table>
		</div>
	</ng-container>
	<ng-container *ngIf="tableInfo.hasTotals">
		<ng-container *ngFor="let col of columns">
			<div class="total {{col.hideAtBp != undefined ? 'hide-at-bp' + col.hideAtBp : ''}}">
				<ng-container *ngIf="col.total">
					<ng-container [ngSwitch]="col.type">
						<span *ngSwitchCase="'currency'">{{sum(col.field) | currency}}</span>
						<span *ngSwitchDefault>{{sum(col.field)}}</span>
					</ng-container>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>
</div>
<app-pager *ngIf="tableInfo.paging" [page]="pageNumber" [pageCount]="pageCount" (onGoToPage)="goToPage($event)"></app-pager>
